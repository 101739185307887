import React from "react";
import * as classes from "./index.module.less";
import { StaticImage } from "gatsby-plugin-image";


let Photo = ({ title, position, children }) => (
  <div className={classes.photo}>
    {children}
    <div className={classes.dark}>
      <h4>{title}</h4>
      <h5>{position}</h5>
    </div>
  </div>
);

let props = {
  placeholder: "blurred",
  layout: "fullWidth",
};

let Photos = () => (
  <div className={classes.container}>
    <Photo title="Shlomo Freund" position="Co-Founder">
      <StaticImage
        src="../../../images/team/shlomo-color.png"
        alt="Shlomo Freund"
        {...props}
      />
    </Photo>
    <Photo title="Rich Bishop" position="Co-Founder & CEO">
      <StaticImage
        src="../../../images/team/rich-color.png"
        alt="Rich Bishop"
        {...props}
      />
    </Photo>
    {/* <Photo title="Irving Diaz" position="CTO">
      <StaticImage
        src="../../../images/team/irving.png"
        alt="Irving Diaz"
        {...props}
      />
    </Photo> */}
    <Photo title="Sally Wang" position="COO">
      <StaticImage
        src="../../../images/team/sally.png"
        alt="Sally Wang"
        {...props}
      />
    </Photo>
    <Photo title="Sissi He" position="Operations Manager">
      <StaticImage
        src="../../../images/team/sissi.png"
        alt="Sissi He"
        {...props}
      />
    </Photo>
    <Photo title="Cecelia Guo" position="Operations Associate">
      <StaticImage
        src="../../../images/team/yaxin.png"
        alt="Cecelia Guo"
        {...props}
      />
    </Photo>
    <Photo title="Tom Zeng" position="Account Manager">
      <StaticImage
        src="../../../images/team/tom2.png"
        alt="Tom Zeng"
        {...props}
      />
    </Photo>
    <Photo title="Rose Zhang" position="Operations Associate">
      <StaticImage
        src="../../../images/team/rose_updated.png"
        alt="Rose Zhang"
        {...props}
      />
    </Photo>
    <Photo title="Shiwen Li" position="Assistant Project Manager">
      <StaticImage
        src="../../../images/team/shiwen_li_2.png"
        alt="Shiwen Li"
        {...props}
      />
    </Photo>
    <Photo title="Felipe Ramírez" position="Head of Engineering">
      <StaticImage
        src="../../../images/team/felipe_r.png"
        alt="Felipe Ramírez"
        {...props}
      />
    </Photo>
    <Photo title="Philipp Neumann" position="Business Development Manager">
      <StaticImage
        src="../../../images/team/philipp.png"
        alt="Philipp Neumann"
        {...props}
      />
    </Photo>
    <Photo title="Yoni Hao" position="Legal Counsel">
      <StaticImage
        src="../../../images/team/yoni.png"
        alt="Yoni Hao"
        {...props}
      />
    </Photo>
    <Photo title="Dan Luo" position="Accounting Specialist">
      <StaticImage
        src="../../../images/team/dan.png"
        alt="Dan Luo"
        {...props}
      />
    </Photo>
    <Photo title="Martin Liu" position="DevOps Lead">
      <StaticImage
        src="../../../images/team/martin_v3.png"
        alt="Martin Liu"
        {...props}
      />
    </Photo>
  </div>
);
export default Photos;
